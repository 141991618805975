<script setup lang="ts">
import { useRoute } from 'vue-router';

import KeyWordFilter from '@Components/filters/KeyWordFilter.vue';

const { $routeNames } = useNuxtApp();

const route = useRoute();

const isMainPage = computed(() => route.name === $routeNames.index);
</script>

<template>
  <CdsContainer
    fluid
    class="search__container d-none d-md-flex align-start py-0 background--color"
    :class="[{ mainSearch__container: isMainPage }, { 'align-center': !isMainPage }]"
  >
    <CdsRow justify="center">
      <CdsCol
        cols="12"
        lg="11"
        xl="10"
      >
        <div
          v-if="isMainPage"
          class="mainSearch__title"
        >
          {{ $t('mainPage.title') }}
        </div>

        <KeyWordFilter />
      </CdsCol>
    </CdsRow>
  </CdsContainer>
</template>

<style lang="scss">
.background--color {
  background-color: map-get($lightSurface, level1);
}
</style>
