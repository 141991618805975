<template>
  <footer>
    <CdsContainer fluid>
      <component :is="krkComponent" />
    </CdsContainer>

    <CdsContainer
      fluid
      class="footer"
    >
      <CdsRow justify="center">
        <CdsCol
          cols="12"
          xl="10"
          lg="11"
        >
          <CdsRow>
            <!-- footer left-side -->
            <CdsCol
              cols="12"
              sm="6"
              class="footer-leftside color--light"
            >
              <a :href="url">
                <img
                  class="logo-footer"
                  src="@/assets/images/logo-elicytacje-logo-footer.svg"
                  alt="E-licytacje - logo stopka"
                />
              </a>
              <p class="footer-paragraph">
                {{ $t('footer.about') }}
              </p>

              <div class="container-komornikpl">
                <p class="footer-text paragraph-komornikpl">
                  {{ $t('footer.visitPage') }}
                </p>
                <a
                  href="https://www.komornik.pl"
                  class="link-komornikpl"
                  title="komornik.pl"
                  id="test--footerKrkLink"
                  target="_blank"
                  >komornik.pl</a
                >
              </div>

              <div class="footer-link color--light">
                <a
                  href="https://www.komornik.pl"
                  :title="$t('footer.krk')"
                  id="test--footerKrkLink"
                  >{{ $t('footer.krk') }}</a
                >
                <span class="dot"></span>
                <NuxtLink
                  :to="{
                    name: $routeNames.rules
                  }"
                  :title="$t('footer.regulation')"
                  id="test--footerRulesLink"
                  >{{ $t('footer.regulation') }}</NuxtLink
                >
                <span class="dot"></span>
                <NuxtLink
                  :to="{
                    name: $routeNames.privacyPolicy
                  }"
                  :title="$t('footer.privacyPolicy')"
                  id="test--footerPrivacyPolicyLink"
                  >{{ $t('footer.privacyPolicy') }}
                </NuxtLink>
                <span class="dot"></span>
                <NuxtLink
                  :to="{
                    name: $routeNames.sendReport
                  }"
                  id="test--footerSubmissionLink"
                  :title="$t('subheader.submissions')"
                  >{{ $t('subheader.submissions') }}</NuxtLink
                >
              </div>
            </CdsCol>
            <!-- end - footer left-side-->
            <!-- footer right-side -->
            <CdsCol
              cols="12"
              sm="6"
              class="footer-rightside"
            >
              <h4 class="footer-rightside--title color--light text-left">{{ $t('footer.viewCategories') }}:</h4>
              <CdsRow class="px-3">
                <CdsCol
                  md="6"
                  cols="12"
                  class="category"
                >
                  <p class="category-paragraph">
                    <NuxtLink
                      :to="{
                        name: $routeNames.itemList,
                        query: {
                          mainCategory: EMainCategories.MOVABLE_NAME,
                          subCategory: `${$t('categories.antiques')}, ${$t('categories.art')}`
                        }
                      }"
                      :title="`${$t('categories.antiques')}, ${$t('categories.art')}}`"
                      id="test--antiquesCategory"
                      >{{ $t('categories.antiques') }},
                      {{ $t('categories.art') }}
                    </NuxtLink>
                  </p>
                  <p class="category-paragraph">
                    <NuxtLink
                      :to="{
                        name: $routeNames.itemList,
                        query: {
                          mainCategory: EMainCategories.MOVABLE_NAME,
                          subCategory: `${$t('categories.jewelry')}, ${$t('categories.watches')}`
                        }
                      }"
                      :title="`${$t('categories.jewelry')}, ${$t('categories.watches')}}`"
                      id="test--jewelryCategory"
                      >{{ $t('categories.jewelry') }},
                      {{ $t('categories.watches') }}
                    </NuxtLink>
                  </p>
                  <p class="category-paragraph">
                    <NuxtLink
                      :to="{
                        name: $routeNames.itemList,
                        query: {
                          mainCategory: EMainCategories.MOVABLE_NAME,
                          subCategory: `${$t('categories.boats')}, ${$t('categories.yachts')}`
                        }
                      }"
                      :title="`${$t('categories.boats')}, ${$t('categories.yachts')}}`"
                      id="test--industrialMachineryCategory"
                      >{{ $t('categories.boats') }},
                      {{ $t('categories.yachts') }}
                    </NuxtLink>
                  </p>
                  <p class="category-paragraph">
                    <NuxtLink
                      :to="{
                        name: $routeNames.itemList,
                        query: {
                          mainCategory: EMainCategories.MOVABLE_NAME,
                          subCategory: $t('categories.industrialMachinery')
                        }
                      }"
                      :title="$t('categories.industrialMachinery')"
                      id="test--industrialMachineryCategory"
                      >{{ $t('categories.industrialMachinery') }}
                    </NuxtLink>
                  </p>
                  <p class="category-paragraph">
                    <NuxtLink
                      :to="{
                        name: $routeNames.itemList,
                        query: {
                          mainCategory: EMainCategories.MOVABLE_NAME,
                          subCategory: $t('categories.agriculturalMachinery')
                        }
                      }"
                      :title="$t('categories.agriculturalMachinery')"
                      id="test--agriculturalMachineryCategory"
                      >{{ $t('categories.agriculturalMachinery') }}
                    </NuxtLink>
                  </p>
                  <p class="category-paragraph">
                    <NuxtLink
                      :to="{
                        name: $routeNames.itemList,
                        query: {
                          mainCategory: EMainCategories.MOVABLE_NAME,
                          subCategory: $t('categories.industrialMaterials')
                        }
                      }"
                      :title="$t('categories.industrialMaterials')"
                      id="test--industrialMaterialsCategory"
                      >{{ $t('categories.industrialMaterials') }}
                    </NuxtLink>
                  </p>
                  <p class="category-paragraph">
                    <NuxtLink
                      :to="{
                        name: $routeNames.itemList,
                        query: {
                          mainCategory: EMainCategories.MOVABLE_NAME,
                          subCategory: $t('categories.furniture')
                        }
                      }"
                      :title="$t('categories.furniture')"
                      id="test--furnitureCategory"
                      >{{ $t('categories.furniture') }}
                    </NuxtLink>
                  </p>
                  <p class="category-paragraph">
                    <NuxtLink
                      :to="{
                        name: $routeNames.itemList,
                        query: {
                          mainCategory: EMainCategories.MOVABLE_NAME,
                          subCategory: `${$t('categories.motorcycles')}, ${$t('categories.scooters')}, ${$t('categories.quads')}`
                        }
                      }"
                      :title="`${$t('categories.motorcycles')},${$t('categories.scooters')}, ${$t('categories.quads')}`"
                      id="test--motorcyclesCategory"
                      >{{ $t('categories.motorcycles') }}, {{ $t('categories.scooters') }},
                      {{ $t('categories.quads') }}
                    </NuxtLink>
                  </p>
                  <p class="category-paragraph">
                    <NuxtLink
                      :to="{
                        name: $routeNames.itemList,
                        query: {
                          mainCategory: EMainCategories.MOVABLE_NAME,
                          subCategory: $t('categories.clothing')
                        }
                      }"
                      :title="$t('categories.clothing')"
                      id="test--clothingCategory"
                      >{{ $t('categories.clothing') }}
                    </NuxtLink>
                  </p>
                </CdsCol>
                <CdsCol
                  lg="6"
                  cols="12"
                  class="category"
                >
                  <p class="category-paragraph">
                    <NuxtLink
                      :to="{
                        name: $routeNames.itemList,
                        query: {
                          mainCategory: EMainCategories.MOVABLE_NAME,
                          subCategory: $t('categories.cars')
                        }
                      }"
                      :title="$t('categories.cars')"
                      id="test--carsCategory"
                      >{{ $t('categories.cars') }}
                    </NuxtLink>
                  </p>
                  <p class="category-paragraph">
                    <NuxtLink
                      :to="{
                        name: $routeNames.itemList,
                        query: {
                          mainCategory: EMainCategories.MOVABLE_NAME,
                          subCategory: $t('categories.otherMovableProperty')
                        }
                      }"
                      :title="$t('categories.otherMovableProperty')"
                      id="test--otherMovablePropertyCategory"
                      >{{ $t('categories.otherMovableProperty') }}
                    </NuxtLink>
                  </p>
                  <p class="category-paragraph">
                    <NuxtLink
                      :to="{
                        name: $routeNames.itemList,
                        query: {
                          mainCategory: EMainCategories.MOVABLE_NAME,
                          subCategory: `${$t('categories.trailers')}, ${$t('categories.semiTrailers')}`
                        }
                      }"
                      :title="`${$t('categories.trailers')} ${$t('categories.semiTrailers')}`"
                      id="test--trailersCategory"
                      >{{ $t('categories.trailers') }},
                      {{ $t('categories.semiTrailers') }}
                    </NuxtLink>
                  </p>
                  <p class="category-paragraph">
                    <NuxtLink
                      :to="{
                        name: $routeNames.itemList,
                        query: {
                          mainCategory: EMainCategories.MOVABLE_NAME,
                          subCategory: $t('categories.lorries')
                        }
                      }"
                      :title="$t('categories.lorries')"
                      id="test--lorriesCategory"
                      >{{ $t('categories.lorries') }}
                    </NuxtLink>
                  </p>
                  <p class="category-paragraph">
                    <NuxtLink
                      :to="{
                        name: $routeNames.itemList,
                        query: {
                          mainCategory: EMainCategories.MOVABLE_NAME,
                          subCategory: $t('categories.householdAppliances')
                        }
                      }"
                      :title="$t('categories.householdAppliances')"
                      id="test--householdAppliancesCategory"
                      >{{ $t('categories.householdAppliances') }}
                    </NuxtLink>
                  </p>

                  <p class="category-paragraph">
                    <NuxtLink
                      :to="{
                        name: $routeNames.itemList,
                        query: {
                          mainCategory: EMainCategories.MOVABLE_NAME,
                          subCategory: $t('categories.hardware')
                        }
                      }"
                      :title="$t('categories.hardware')"
                      id="test--hardwareCategory"
                      >{{ $t('categories.hardware') }}
                    </NuxtLink>
                  </p>
                  <p class="category-paragraph">
                    <NuxtLink
                      :to="{
                        name: $routeNames.itemList,
                        query: {
                          mainCategory: EMainCategories.MOVABLE_NAME,
                          subCategory: $t('categories.RTVEquipment')
                        }
                      }"
                      :title="$t('categories.RTVEquipment')"
                      id="test--rtvEquipmentCategory"
                      >{{ $t('categories.RTVEquipment') }}
                    </NuxtLink>
                  </p>
                  <p class="category-paragraph">
                    <NuxtLink
                      :to="{
                        name: $routeNames.itemList,
                        query: {
                          mainCategory: EMainCategories.MOVABLE_NAME,
                          subCategory: $t('categories.rawMaterials')
                        }
                      }"
                      :title="$t('categories.rawMaterials')"
                      id="test--rawMaterialsCategory"
                      >{{ $t('categories.rawMaterials') }}
                    </NuxtLink>
                  </p>

                  <p class="category-paragraph see-all">
                    <NuxtLink
                      :to="{
                        name: $routeNames.itemList
                      }"
                      :title="$t('categories.seeAll')"
                      >{{ $t('categories.seeAll') }}
                    </NuxtLink>
                  </p>

                  <p class="category-paragraph d-none">
                    <a
                      :href="url + '/sitemap'"
                      title="Mapa strony"
                      >Mapa Strony</a
                    >
                  </p>
                </CdsCol>
              </CdsRow>
            </CdsCol>

            <!-- end - footer right-side -->
            <CdsCol cols="12">
              <p class="footer-text footer-text__margins">
                {{ currentYear() }} {{ $t('footer.krk') }} - {{ $t('footer.version') }} {{ version() }}
              </p>
            </CdsCol>
          </CdsRow>
        </CdsCol>
      </CdsRow>
    </CdsContainer>
  </footer>
</template>

<script setup lang="ts">
import { computed, defineAsyncComponent } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';

import { useUserAuthStore } from '~coreStores/user-auth.store';

import { EMainCategories } from '@Models/Item/filter.model';

const route = useRoute();
const userAuthStore = useUserAuthStore();
const { getBailiffOfficeUserData } = storeToRefs(userAuthStore);

const runtimeConfig = useRuntimeConfig();

const url: string | undefined = process.client ? window.location.origin : process.env.baseUrl;

const userIsBailiff = computed(() => !!getBailiffOfficeUserData.value);

const version = () => {
  return sessionStorage.getItem('systemVersion');
};

const currentYear = (): number => {
  const d = new Date();
  return d.getFullYear();
};

const showKrkInfo = computed(() => {
  return (route.name as string)?.includes('index') ?? false;
});

const krkComponent = computed(() => {
  if (showKrkInfo.value) return defineAsyncComponent(() => import('@Components/layout/KrkInfo.vue'));
});
</script>

<style scoped lang="scss">
@import '@Assets/scss/footer-old.scss';
</style>
