<template>
  <CdsForm
    class="w-100 d-flex max--height"
    @submit.prevent="searchItems()"
  >
    <CdsRow>
      <CdsCol
        cols="10"
        class="pr-0"
      >
        <div class="p-relative">
          <CdsTextField
            v-model="text"
            :placeholder="$t('searchBar.placeholder')"
            class="text--filter"
            type="default"
            size="regular"
            :class="[isMainPage ? 'text--filter__background-dark' : 'text--filter__background-light']"
            @update:modelValue="delaySearching()"
            @click="showHints = true"
          />
          <CdsList
            v-if="showHints && hints.length"
            ref="selectList"
            class="select--list"
          >
            <CdsListItem
              v-for="(hint, index) in hints"
              :key="index"
              :to="{
                name: $routeNames.itemList,
                query: {
                  notes: hint.value
                }
              }"
              :exact="true"
            >
              <template #title>
                <div v-html="hint.key"></div>
              </template>
            </CdsListItem>
          </CdsList>
        </div>
      </CdsCol>
      <CdsCol
        cols="2"
        class="pl-0"
      >
        <CdsBtn
          class="search__container-btn"
          size="large"
          id="mainSearchButton"
          button-type="submit"
        >
          {{ $t('searchBar.button') }}
        </CdsBtn>
      </CdsCol>
    </CdsRow>
  </CdsForm>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import _debounce from 'lodash.debounce';
import { useSnackbar } from 'currenda-design-system';
import { onClickOutside } from '@vueuse/core';

import { highlightsService } from '@Services/filter.service';

import type { HighlightI } from '@Models/Item/filter.model';

const { $i18n, $routeNames } = useNuxtApp();
const { create } = useSnackbar();
const router = useRouter();
const route = useRoute();

const showHints = ref(false);
const text = ref('');
const delaySearching = _debounce(fetchHints, 300);

const highlights = ref<HighlightI[]>([]);

const selectList = ref();
onClickOutside(selectList, () => hideHints());

const hints = computed(() => {
  return highlights.value
    .map((hint) => {
      const regex = new RegExp(text.value, 'gi');
      if (hint.key.match(regex) !== null) {
        return {
          value: hint.key,
          key: hint.key.replace(regex, (str: any) => {
            return `<span class="font-weight-black">${str}</span>`;
          })
        };
      }
    })
    .filter((el) => el !== undefined);
});

watch(
  () => route.name,
  () => {
    text.value = '';
    hideHints();
    highlights.value = [];
  }
);

async function fetchHints() {
  if (!text.value.trim()) return;

  try {
    const response = await highlightsService(text.value);

    highlights.value = response;

    showHints.value = true;
  } catch (error) {
    create({
      message: $i18n.t('navbar.searchBar.error'),
      type: 'error'
    });
  }
}

function searchItems() {
  if (isFilterPage.value) {
    router.push({
      query: {
        ...route.query,
        notes: text.value
      }
    });
  } else {
    if (text.value) {
      router.push({
        name: $routeNames.itemList,
        query: {
          notes: text.value
        }
      });
    } else {
      router.push({
        name: $routeNames.itemList
      });
    }
  }

  hideHints();
}

function hideHints() {
  showHints.value = false;
}

const isMainPage = computed(() => route.name === $routeNames.index);
const isFilterPage = computed(() => route.name === $routeNames.itemList);
</script>

<style lang="scss" scoped>
@use '~core/cds-assets/scss/helpers' as *;
@use '~core/assets/scss/mixins' as mixins;

.max--height {
  max-height: 100px;
}

.text--filter {
  --cds-input-border-radius-top-right: 0;
  --cds-input-border-radius-bottom-right: 0;
  --cds-input-border: 0;
}

.select--list {
  @include cdsBgColor('surface00');
  @include cdsBoxShadow('md');
  @include mixins.customScrollbar();
  position: absolute;
  top: 56px;
  left: 0;
  width: 100%;
  z-index: 10;
  max-height: 200px;
  height: auto;
  border-bottom-left-radius: cdsBorderRadius('xs');
  border-bottom-right-radius: cdsBorderRadius('xs');
}
</style>

<style lang="scss">
.text--filter {
  input {
    height: 56px;
  }

  &__background-light input {
    background: map-get($lightSurface, level0);
  }

  &__background-dark input {
    background: map-get($lightSurface, level1);
  }
}
</style>
