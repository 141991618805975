<script setup lang="ts">
import config from '../config';
import { useRoute } from 'vue-router';

import { useToasts } from '@Composables/toast';

import FooterOld from '@Components/layout/FooterOld.vue';
import Header from '@Components/layout/Header.vue';
import SubHeader from '@Components/layout/SubHeader.vue';
import ItemSearchBox from '@Components/layout/ItemSearchBox.vue';

const { $i18n } = useNuxtApp();

const { setErrorToast } = useToasts();

useHead({
  title: $i18n.t('footer.krk'),
  titleTemplate: `%s - ${$i18n.t('seo.title')}`,
  htmlAttrs: {
    lang: $i18n.locale.value
  },
  bodyAttrs: {
    class: 'public-default-layout'
  },
  meta: [
    {
      name: 'description',
      content: $i18n.t('seo.description')
    },
    {
      name: 'keywords',
      content: $i18n.t('seo.keywords')
    }
  ]
});

const renderError = ref(false);
const route = useRoute();

const defaultError = config.messages.unidentifiedProblem;

const routeName = computed<string>(() => {
  return route.name as string;
});

const showSearchBar = computed(() => {
  return !routeName.value?.includes('items-details') && !routeName.value?.includes('bailiff-edit-bidder-account');
});

const lightLevel1Bg = computed(() => {
  return routeName.value?.includes('edit-bidder-account');
});

onErrorCaptured((err: unknown, instance: ComponentPublicInstance | null, info: string) => {
  renderError.value = info === 'render'; // indicates a template rendering error
  if (err) {
    setErrorToast(err);
  }
  console.log('error handled in layout', err, instance, info);
  return false; // stops the error from propagating further
});
</script>

<template>
  <ClientOnly>
    <div class="elkrk-layout">
      <Header />
      <SubHeader />
      <ItemSearchBox v-if="showSearchBar" />
      <div :class="{ 'background--light-1': lightLevel1Bg }">
        <cds-container fluid>
          <slot v-if="!renderError" />
          <div v-if="renderError">{{ defaultError }}</div>
        </cds-container>
      </div>
      <FooterOld />
    </div>
  </ClientOnly>
</template>
