<template>
  <cds-dialog
    v-model="showMenu"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    class="navigation"
  >
    <cds-card>
      <cds-toolbar
        light
        class="nav-topsubheaderold__header"
      >
        <cds-toolbar-title>
          <img
            src="@/assets/images/logo-elicytacje-logo-header.svg"
            class="nav-topsubheader__container--img cursor--pointer"
            alt="E-licytacje Komornicze Logo"
            :to="baseUrl"
          />
        </cds-toolbar-title>
        <cds-spacer></cds-spacer>
        <cds-toolbar-items>
          <CdsBtn
            icon="cross"
            size="regular"
            variant="text"
            @click="hideModal"
          />
        </cds-toolbar-items>
      </cds-toolbar>

      <h1 class="nav-topsubheaderold__label">MENU</h1>

      <div class="nav-topsubheaderold__cardActions navigation--border-bottom">
        <div class="nav-topsubheaderold--border-top">
          <NuxtLink
            class="nav-topsubheaderold__title"
            :to="{
              name: $routeNames.index
            }"
            @click.prevent="hideModal"
          >
            {{ $t('subheader.mainPage') }}
          </NuxtLink>
        </div>
      </div>

      <template v-if="getActiveContext">
        <div class="nav-topsubheaderold__cardActions navigation--border-bottom">
          <div class="nav-topsubheaderold--border-top">
            <NuxtLink
              class="nav-topsubheaderold__title"
              :to="{
                name: $routeNames.bailiff.auctions.index
              }"
            >
              {{ $t('navbar.myAuctions') }}
            </NuxtLink>
          </div>
        </div>

        <div class="nav-topsubheaderold__cardActions navigation--border-bottom">
          <div class="nav-topsubheaderold--border-top">
            <NuxtLink
              class="nav-topsubheaderold__title"
              @click.prevent="showBailiffAccount"
            >
              {{ $t('header.myAccount') }}
            </NuxtLink>
          </div>
        </div>

        <div class="nav-topsubheaderold__cardActions navigation--border-bottom">
          <div class="nav-topsubheaderold--border-top">
            <NuxtLink
              class="nav-topsubheaderold__title"
              @click.prevent="showManageBidderAccount"
            >
              {{ $t('subheader.manageBidderAccount') }}
            </NuxtLink>
          </div>
        </div>
      </template>

      <CdsAccordionGroup
        variant="plain"
        type="default"
        size="regular"
        icon-position="end"
        headerHeight="30px"
      >
        <CdsAccordion
          v-for="(item, index) in items.filter((item) => item.show)"
          :key="index"
          :title="item.title"
        >
          <div
            v-for="(subtitle, index) in item.subtitle"
            :key="index"
            @click="hideModal"
            class="nav-topsubheaderold__header__container__item"
          >
            <NuxtLink
              v-if="subtitle.to"
              :to="{ name: subtitle.to }"
              class="nav__submenu--link w-100"
              :title="subtitle.text"
              :id="subtitle.IdForAutomaticTest"
            >
              {{ subtitle.text }}
            </NuxtLink>
            <a
              v-else
              :href="subtitle.href"
              class="nav__submenu--link w-100"
              :title="subtitle.text"
              :id="subtitle.IdForAutomaticTest"
              @click="redirectSublink(`${subtitle.href}`)"
            >
              {{ subtitle.text }}
            </a>
          </div>
        </CdsAccordion>
      </CdsAccordionGroup>

      <div
        v-if="!getUserData"
        class="nav-topsubheaderold__button mt-sm"
        @click="register"
      >
        {{ $t('navbar.registerAccount') }}
      </div>

      <div class="nav-topsubheaderold__footer">
        <span
          class="nav-topsubheaderold__footer--text"
          @click="hideModal"
          >{{ $t('navbar.closeMenu') }}</span
        >
      </div>
    </cds-card>
  </cds-dialog>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import type { ItemNavigationModel } from '@Models/Layout/itemNavigation.model';
import { useContextStore } from '~coreStores/context.store';
import { useUserAuthStore } from '~coreStores/user-auth.store';

import { getCookie } from '@/services/helpers';

const emit = defineEmits<{
  hide: [];
  toggleManageBidderAccountModal: [];
  toggleBailiffAccountModal: [];
}>();

const userAuthStore = useUserAuthStore();
const { getUserData, getBidderUserData } = storeToRefs(userAuthStore);

const useContext = useContextStore();
const { getActiveContext } = storeToRefs(useContext);

const runtimeConfig = useRuntimeConfig();
const { $i18n, $routeNames } = useNuxtApp();
const showMenu = ref(true);

const items = computed((): ItemNavigationModel[] => {
  return [
    {
      title: $i18n.t('navbar.myAuctions'),
      href: '/',
      active: false,
      IdForAutomaticTest: 'test--myActionsLink',
      show: !!getBidderUserData.value,
      subtitle: [
        {
          text: $i18n.t('navbar.myAuctions'),
          to: $routeNames.user.myAuctions,
          show: true,
          IdForAutomaticTest: 'text--myAuctions'
        },
        {
          text: $i18n.t('buttons.myComplaints'),
          href: `/user/complaints`,
          show: true,
          IdForAutomaticTest: 'text--myComplaints'
        }
      ],
      showMoreBtn: true
    },
    {
      title: $i18n.t('subheader.help'),
      href: '/',
      active: false,
      IdForAutomaticTest: 'test--helpLink',
      show: true,
      subtitle: [
        {
          text: 'FAQ',
          show: true,
          name: 'faqs',
          to: $routeNames.faqs,
          IdForAutomaticTest: 'test--faqs'
        },
        {
          text: $i18n.t('subheader.regulation'),
          show: true,
          name: 'rules',
          IdForAutomaticTest: 'test--rules',
          to: $routeNames.rules
        },
        {
          text: $i18n.t('subheader.submissions'),
          show: true,
          name: 'send-report',
          IdForAutomaticTest: 'test--sendReport',
          to: $routeNames.sendReport
        }
      ],
      showMoreBtn: true
    },
    {
      title: $i18n.t('subheader.links'),
      href: '/',
      active: false,
      IdForAutomaticTest: 'test--linksLink',
      show: true,
      subtitle: [
        {
          text: $i18n.t('subheader.ool'),
          href: 'https://licytacje.komornik.pl',
          show: true,
          IdForAutomaticTest: 'test--oolLink'
        },
        {
          text: 'Komornik.pl',
          href: 'https://www.komornik.pl/',
          show: true,
          IdForAutomaticTest: 'test--komornikLink'
        },
        {
          text: $i18n.t('subheader.findBaillif'),
          href: 'https://www.komornik.pl/?page_id=195#menu',
          show: true,
          IdForAutomaticTest: 'test--findBailiffLink'
        },
        {
          text: 'Twojealimenty.pl',
          href: 'https://twojealimenty.pl/',
          show: true,
          IdForAutomaticTest: 'test--twojealimentyLink'
        }
      ],
      showMoreBtn: true
    }
  ];
});

const externalLinks: string[] = [
  'https://licytacje.komornik.pl',
  'https://www.komornik.pl/',
  'https://www.komornik.pl/?page_id=195#menu',
  'https://twojealimenty.pl/'
];

const baseUrl = computed(() => {
  return process.client ? window.location.origin : process.env.baseUrl;
});

const langCode = computed(() => {
  return getCookie('i18n_redirected') !== 'pl' ? getCookie('i18n_redirected') + '/' : '';
});

function hideModal() {
  emit('hide');
}

function register() {
  navigateTo({ name: $routeNames.register });
  hideModal();
}

function redirectSublink(link: string) {
  if (externalLinks.includes(link)) {
    window.location.href = link;
    return;
  } else {
    window.location.href = runtimeConfig.baseUrl != null ? `${runtimeConfig.baseUrl}${langCode}${link}` : '';
  }
}

function showManageBidderAccount() {
  emit('hide');
  emit('toggleManageBidderAccountModal');
}

function showBailiffAccount() {
  emit('hide');
  emit('toggleBailiffAccountModal');
}
</script>

<style lang="scss">
.navigation .cds-accordion > button {
  font-family: $projectFont;
  font-size: map-get($fontSizes, h6);
  color: map-get($onLightSurface, level2);
  letter-spacing: normal;
  font-weight: map-get($fontWeights, hard) !important;
  --cds-accordion-header-padding-left: 40px;
}
</style>

<style lang="scss" scoped>
@use '~core/cds-assets/scss/helpers' as *;

.navigation--border-bottom {
  border-bottom: 1px solid cdsColor('surface40');
}
</style>
