<template>
  <CdsDialog
    v-model="openSearchBarMobile"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <div class="mobile-search bg-color-surface00 p-md">
      <div class="d-flex">
        <img
          src="@/assets/images/logo-elicytacje-logo-header.png"
          class="header__img"
          alt="logo"
        />

        <CdsSpacer></CdsSpacer>

        <CdsBtn
          icon="cross"
          variant="text"
          size="regular"
          @click="hideModal()"
        />
      </div>

      <CdsText
        variant="normal-h3"
        color="mid-emphasis-on-surface"
        class="mt-xl mb-sm"
        >{{ $t('searchBar.button') }}</CdsText
      >

      <div class="mobile-search__body px-md py-xl -ml-md -mr-md">
        <div class="mobile-search__content">
          <CdsText
            variant="normal-h2"
            color="hi-emphasis-on-color"
            class="mb-md"
            >{{ $t('mainPage.title') }}</CdsText
          >
          <CdsForm
            class="w-100"
            @submit.prevent="searchItems"
          >
            <CdsTextField
              v-model="searchText"
              size="large"
              hide-details
              :placeholder="$t('searchBar.placeholder')"
              class="autocomplete-search"
            />
            <CdsBtn
              size="large"
              variant="solid"
              button-type="submit"
              block
            >
              {{ $t('searchBar.button') }}
            </CdsBtn>
          </CdsForm>
        </div>
      </div>
      <div class="mobile-search__footer">
        <span
          class="mobile-search__footer--text"
          @click="hideModal"
          >{{ $t('searchBar.closeBtn') }}</span
        >
      </div>
    </div>
  </CdsDialog>
</template>

<script lang="ts" setup>
const openSearchBarMobile = defineModel<boolean>();
const route = useRoute();
const { $routeNames } = useNuxtApp();

const searchText = ref('');

function searchItems() {
  navigateTo({
    name: $routeNames.itemList,
    query: {
      ...route.query,
      notes: searchText.value
    }
  });

  hideModal();
}

function hideModal() {
  openSearchBarMobile.value = false;
}
</script>
