<script setup lang="ts">
import { computed, defineAsyncComponent } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute, type RouterLinkProps } from 'vue-router';
import type { LigatureT } from 'currenda-design-system/dist/types/global/props/icon-props.types';

import { useContextStore } from '~coreStores/context.store';
import { useUserAuthStore } from '~coreStores/user-auth.store';

import { useEndSessionCountdown } from '~coreComposables/end-session-countdown.composable';

import { useModalStore } from '@Store/modal.module';

import { useELKRKRoute as useRouteComposable } from '@Composables/elkrk-route';
import { useModal } from '@Composables/modal';

const EndSession = defineAsyncComponent(() => import('@Components/EndSession.vue'));

const OldMobileHeader = computed(() => {
  if (openMobile.value) return defineAsyncComponent(() => import('@Components/layout/OldMobileHeader.vue'));
});

const componentManageBidderAccountModal = computed(() => {
  if (showManageBidderAccountModal.value)
    return defineAsyncComponent(() => import('@Components/edit-bidder-account/ManageBidderAccountModal.vue'));
});

const componentBailiffAccountModal = computed(() => {
  if (showBailiffAccountModal.value)
    return defineAsyncComponent(() => import('@Components/bailiff-account/BailiffAccountModal.vue'));
});

const { $routeNames } = useNuxtApp();
const route = useRoute();
const modalStore = useModalStore();
const { redirectToLoginAsBidder } = useRouteComposable();
const { BPIsSMAndDown } = useModal();

const { $i18n } = useNuxtApp();

const contextStore = useContextStore();
const { getActiveContext, getShortContextName } = storeToRefs(contextStore);

const userAuthStore = useUserAuthStore();
const { getUserData, getBailiffOfficeUserData, getBidderUserData, showEndSessionDialog } = storeToRefs(userAuthStore);
const { expirationTime, getExpirationTextInfo } = useEndSessionCountdown();

const openMobile = ref(false);
const showManageBidderAccountModal = ref(false);
const showBailiffAccountModal = ref(false);

interface Item {
  title: string;
  show: boolean | null;
  icon: LigatureT;
  id: string;
  href?: string;
  action?: string;
  bailiffHref?: string;
  to?: RouterLinkProps['to'];
}

const items = computed((): Item[] => {
  const items: Item[] = [
    {
      title: $i18n.t('navbar.logInAsBidder'),
      action: 'loginAsBidder',
      show: !getUserData.value,
      icon: 'user',
      id: 'test--login--bidder'
    },
    {
      title: $i18n.t('navbar.logInAsBailiff'),
      to: { name: $routeNames.bailiff.login },
      show: !getUserData.value,
      icon: 'hammer',
      id: 'login'
    },
    {
      title: $i18n.t('navbar.myAuctions'),
      to: { name: $routeNames.user.myAuctions },
      show: !!getUserData.value && !!getBidderUserData.value,
      icon: 'hammer',
      id: 'user-my-auctions'
    },
    {
      title: $i18n.t('navbar.myAuctions'),
      to: { name: $routeNames.bailiff.auctions.index },
      show: !!getUserData.value && !!getBailiffOfficeUserData.value,
      icon: 'hammer',
      id: 'dashboard'
    },
    {
      title: $i18n.t('navbar.myAccount'),
      to: { name: $routeNames.user.myAccount },
      show: !!getUserData.value && !!getBidderUserData.value,
      icon: 'user',
      id: 'moj-profil'
    },
    {
      title: $i18n.t('navbar.myAccount'),
      action: 'bailiffAccount',
      show: !!getUserData.value && !!getBailiffOfficeUserData.value,
      icon: 'user',
      id: 'moj-profil-bailiff'
    },
    {
      title: $i18n.t('subheader.manageBidderAccount'),
      action: 'manageBidderAccount',
      show: getBailiffOfficeUserData.value && !!getUserData.value,
      icon: 'user_interview',
      id: 'bailiff-edit-bidder-account'
    },
    {
      title: $i18n.t('navbar.changeTheContext'),
      to: { name: $routeNames.bailiff.context },
      show: getBailiffOfficeUserData.value && !!getUserData.value,
      icon: 'home',
      id: 'context-bailiff'
    },
    {
      title: $i18n.t('subheader.help'),
      to: { name: $routeNames.faqs },
      show: getBidderUserData.value && !!getUserData.value,
      icon: 'info_circle',
      id: 'faqs'
    },
    {
      title: $i18n.t('subheader.help'),
      to: { name: $routeNames.bailiff.faqs },
      show: getBailiffOfficeUserData.value && !!getUserData.value,
      icon: 'info_circle',
      id: 'bailiff-faqs'
    },
    {
      title: $i18n.t('navbar.logOut'),
      action: 'logout',
      show: !!getUserData.value,
      icon: 'sign_out',
      id: 'logout-button'
    }
  ];

  return items.filter((item) => item.show);
});

function action(item: Item) {
  if (item.to) return;

  if (item.href || item.bailiffHref) {
    const url = (process as any).client ? window.location.origin : process.env.baseUrl;
    const link = getActiveContext.value ? item.bailiffHref : item.href;
    window.location.href = `${url}${link}`;
  } else if (item.action) {
    actionHandler(item.action);
  }
}

function actionHandler(action: string) {
  switch (action) {
    case 'logout':
      showEndSessionDialog.value = false;
      userAuthStore.logoutUser();
      break;
    case 'loginAsBidder':
      redirectToLoginAsBidder(route.path);
      break;
    case 'manageBidderAccount':
      toggleManageBidderAccountModal();
      break;
    case 'bailiffAccount':
      toggleBailiffAccountModal();
      break;
    default:
      return;
  }
}

// What about that? Unused
function openMobileMenu() {
  if (!BPIsSMAndDown.value) return;

  modalStore.toggleOverflow(true);
  openMobile.value = true;
}

function toggleManageBidderAccountModal() {
  showManageBidderAccountModal.value = !showManageBidderAccountModal.value;
}

function toggleBailiffAccountModal() {
  showBailiffAccountModal.value = !showBailiffAccountModal.value;
}
</script>

<template>
  <CdsToolbar
    color="#093468"
    class="nav-top"
  >
    <CdsRow justify="center">
      <CdsCol
        cols="12"
        xl="10"
        lg="11"
      >
        <div class="d-flex align-center nav-top__subContainer">
          <!-- 
          // 21.01.2025 - na ten moment nie ma mowy o zmianie języka w PLK.
          <span v-if="isTest()">
            <NuxtLinkLocale
              v-for="(_locale, index) in $i18n.availableLocales"
              class="d-flex align-center"
              :key="index"
              :to="switchLocalePath(_locale)"
            >
              <img
                class="nav__flag mr-md"
                :src="`/images/png/${_locale}.png`"
              />
            </NuxtLinkLocale>
          </span> -->

          <div
            v-if="getUserData && expirationTime && getExpirationTextInfo"
            class="d-none d-md-flex nav__timer"
          >
            <div>{{ getExpirationTextInfo.label }}<br />{{ getExpirationTextInfo.value }}</div>
          </div>

          <CdsSpacer class="d-none d-sm-flex"></CdsSpacer>

          <NuxtLink
            v-if="getShortContextName && getUserData"
            class="nav__context"
            :to="{ name: $routeNames.bailiff.context }"
          >
            {{ getShortContextName }}
          </NuxtLink>

          <CdsIcon
            v-if="BPIsSMAndDown"
            icon="user_circle"
            size="lg"
            class="ml-auto c--pointer"
            @click="openMobileMenu"
          />

          <CdsMenu
            v-else
            class="nav-top__menu"
            scroll-strategy="close"
            location="bottom right"
            :min-width="250"
            :offset="[10, -15]"
          >
            <template v-slot:activator="{ props }">
              <CdsBtn
                color="surface00"
                v-bind="props"
                variant="text"
                prepend-icon="user"
                append-icon="chevron_down"
                size="regular"
              >
                <span
                  v-if="getUserData?.name"
                  class="d-none d-md-block nav-top__user-auctions"
                >
                  {{ $t('navbar.greeting') }} {{ getUserData.name }}
                </span>

                <span
                  v-if="!getUserData"
                  class="d-none d-md-block nav-top__user-auctions"
                >
                  {{ $t('navbar.myAuctions') }}
                </span>
              </CdsBtn>
            </template>

            <CdsList scroll-strategy="close">
              <CdsListItem
                v-for="(item, _index) in items"
                :value="_index"
                :key="_index"
                :id="item.id"
                :title="item.title"
                :prepend-icon="item.icon"
                :to="item.to ? item.to : undefined"
                @click="action(item)"
              />
            </CdsList>
          </CdsMenu>
        </div>
      </CdsCol>
    </CdsRow>

    <component
      :is="OldMobileHeader"
      @hide="openMobile = $event"
      @toggleBailiffAccountModal="toggleBailiffAccountModal"
    />

    <component :is="EndSession" />
  </CdsToolbar>

  <component
    :is="componentManageBidderAccountModal"
    v-on:close="toggleManageBidderAccountModal"
  />

  <component
    :is="componentBailiffAccountModal"
    @close="toggleBailiffAccountModal"
  />
</template>

<style scoped lang="scss">
@import '@Assets/scss/header.scss';
</style>
