<template>
  <cds-container
    fluid
    class="nav-topsubheader"
  >
    <cds-row justify="center">
      <cds-col
        cols="12"
        lg="11"
        xl="10"
      >
        <div
          ref="topSubheader"
          class="nav-topsubheader__container nav-top__subContainer align-center d-flex justify-space-between w-100"
        >
          <NuxtLink :to="{ name: $routeNames.index }">
            <img
              src="@/assets/images/logo-elicytacje-logo-header.svg"
              class="nav-topsubheader__container--img cursor--pointer"
              alt="E-licytacje Komornicze"
            />
          </NuxtLink>
          <template
            v-for="(item, index) in items"
            :key="index"
          >
            <div
              v-if="item.show"
              class="nav-topsubheader__container__item d-none d-md-inline"
            >
              <div
                class="nav__menu"
                @click="openHide(item, index)"
              >
                <div
                  class="nav__menu--parent"
                  :class="{
                    'nav__menu--dropdown': item.showMoreBtn
                  }"
                  :id="item.IdForAutomaticTest"
                >
                  {{ item.title }}
                </div>
                <div
                  class="nav__submenu"
                  :class="{
                    'nav__submenu--shown d-grid': item.title === activeMenu
                  }"
                  v-if="item.subtitle.length"
                >
                  <div class="d-contents">
                    <template v-for="(subitem, index) in item.subtitle.filter((item) => item.show)">
                      <NuxtLink
                        v-if="subitem.to"
                        :to="{ name: subitem.to }"
                        class="nav__submenu--link w-100"
                        :title="subitem.text"
                        :id="subitem.IdForAutomaticTest"
                      >
                        {{ subitem.text }}
                      </NuxtLink>
                      <a
                        v-else
                        :href="subitem.href"
                        class="nav__submenu--link w-100"
                        :title="subitem.text"
                        :id="subitem.IdForAutomaticTest"
                        @click="redirectSublink(subitem)"
                      >
                        {{ subitem.text }}
                      </a>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div
            class="nav-topsubheader__container__button d-none d-lg-flex font--normal"
            @click="register"
            v-if="!getUserData && !loading"
            id="test--registerUser"
          >
            {{ $t('navbar.registerAccount') }}
          </div>
          <div
            class="nav-topsubheader__container__button--visibility"
            v-else
          ></div>
          <div class="d-inline-flex gap-sm d-md-none nav-topsubheader__container__hamburger">
            <OldMobileSubheader
              v-if="openSubHeaderMobile"
              v-on:hide="toggleMobileMenu"
              v-on:toggleManageBidderAccountModal="toggleManageBidderAccountModal"
              v-on:toggleBailiffAccountModal="toggleBailiffAccountModal"
            />

            <OldMobileSearchBox v-model="openSearchBarMobile" />

            <CdsBtn
              icon="search"
              variant="text"
              color="hi-emphasis-on-surface"
              size="regular"
              @click="openSearchBarMobileMenu"
            />

            <CdsBtn
              icon="hamburger_menu"
              variant="text"
              color="hi-emphasis-on-surface"
              size="regular"
              @click="toggleMobileMenu"
            />
          </div>
        </div>
      </cds-col>
    </cds-row>
  </cds-container>
  <component
    :is="componentManageBidderAccountModal"
    v-on:close="toggleManageBidderAccountModal"
  />
  <component
    :is="componentBailiffAccountModal"
    @close="toggleBailiffAccountModal"
  />
</template>

<script setup lang="ts">
import { computed, onMounted, ref, reactive, defineAsyncComponent } from 'vue';
import { useRoute } from 'vue-router';
import { onClickOutside } from '@vueuse/core';

import { useContextStore } from '~coreStores/context.store';
import { useUserAuthStore } from '~coreStores/user-auth.store';
import type { ItemNavigationModel } from '@Models/Layout/itemNavigation.model';

import { getCookie } from '@Services/helpers.js';

import OldMobileSubheader from '@Components/layout/OldMobileSubheader.vue';
import OldMobileSearchBox from '@Components/layout/OldMobileSearchBox.vue';

const userAuthStore = useUserAuthStore();
const { getUserData, getBidderUserData } = storeToRefs(userAuthStore);

const contextStore = useContextStore();
const route = useRoute();
const { $i18n } = useNuxtApp();
const runtimeConfig = useRuntimeConfig();
const loading = ref<boolean>(true);
const openSearchBarMobile = ref(false);
const openSubHeaderMobile = ref(false);
let beforeItem = reactive({});
const activeMenu = ref<string | null>(null);
const externalLinks = [
  'https://licytacje.komornik.pl',
  'https://www.komornik.pl/',
  'https://www.komornik.pl/?page_id=195#menu',
  'https://twojealimenty.pl/'
];
const showManageBidderAccountModal = ref(false);
const showBailiffAccountModal = ref(false);

const { $routeNames } = useNuxtApp();

const topSubheader = ref();
onClickOutside(topSubheader, () => hideAll());

const componentManageBidderAccountModal = computed(() => {
  if (showManageBidderAccountModal.value)
    return defineAsyncComponent(() => import('@Components/edit-bidder-account/ManageBidderAccountModal.vue'));
});

const componentBailiffAccountModal = computed(() => {
  if (showBailiffAccountModal.value)
    return defineAsyncComponent(() => import('@Components/bailiff-account/BailiffAccountModal.vue'));
});

const langCode = computed(() => {
  return getCookie('i18n_redirected') !== 'pl' ? getCookie('i18n_redirected') + '/' : '';
});

watch(
  () => route.name,
  () => {
    hideAll();
  }
);

const items = computed((): ItemNavigationModel[] => {
  return [
    {
      title: $i18n.t('subheader.mainPage'),
      active: false,
      subtitle: [],
      showMoreBtn: false,
      IdForAutomaticTest: 'test--mainPageLink',
      to: $routeNames.index,
      show: true
    },
    {
      title: $i18n.t('navbar.myAuctions'),
      active: false,
      subtitle: [],
      showMoreBtn: false,
      IdForAutomaticTest: 'test--myActionsLink',
      to: $routeNames.bailiff.auctions.index,
      show: !!contextStore.getActiveContext
    },
    {
      title: $i18n.t('navbar.myAuctions'),
      href: '/',
      active: false,
      IdForAutomaticTest: 'test--myActionsLink',
      show: !!getBidderUserData.value,
      subtitle: [
        {
          text: $i18n.t('navbar.myAuctions'),
          to: $routeNames.user.myAuctions,
          show: true,
          IdForAutomaticTest: 'text--myAuctions'
        },
        {
          text: $i18n.t('buttons.myComplaints'),
          href: `/user/complaints`,
          show: true,
          IdForAutomaticTest: 'text--myComplaints'
        }
      ],
      showMoreBtn: true
    },
    {
      title: $i18n.t('subheader.help'),
      href: '/',
      active: false,
      IdForAutomaticTest: 'test--helpLink',
      show: true,
      subtitle: [
        {
          text: 'FAQ',
          show: true,
          name: 'faqs',
          to: $routeNames.faqs,
          IdForAutomaticTest: 'test--faqs'
        },
        {
          text: $i18n.t('subheader.regulation'),
          show: true,
          name: 'rules',
          IdForAutomaticTest: 'test--rules',
          to: $routeNames.rules
        },
        {
          text: $i18n.t('subheader.submissions'),
          show: true,
          name: 'send-report',
          IdForAutomaticTest: 'test--sendReport',
          to: $routeNames.sendReport
        }
      ],
      showMoreBtn: true
    },
    {
      title: $i18n.t('subheader.links'),
      href: '/',
      active: false,
      IdForAutomaticTest: 'test--linksLink',
      show: true,
      subtitle: [
        {
          text: $i18n.t('subheader.ool'),
          href: 'https://licytacje.komornik.pl',
          show: true,
          IdForAutomaticTest: 'test--oolLink'
        },
        {
          text: 'Komornik.pl',
          href: 'https://www.komornik.pl/',
          show: true,
          IdForAutomaticTest: 'test--komornikLink'
        },
        {
          text: $i18n.t('subheader.findBaillif'),
          href: 'https://www.komornik.pl/?page_id=195#menu',
          show: true,
          IdForAutomaticTest: 'test--findBailiffLink'
        },
        {
          text: 'Twojealimenty.pl',
          href: 'https://twojealimenty.pl/',
          show: true,
          IdForAutomaticTest: 'test--twojealimentyLink'
        }
      ],
      showMoreBtn: true
    }
  ];
});

function openHide(item: ItemNavigationModel, index: number) {
  if (item?.to) {
    navigateTo({ name: item.to });
    return;
  }

  if (beforeItem === items.value[index]) {
    beforeItem = {};
  } else {
    beforeItem = item;
  }
  hideAll();
  activeMenu.value = item.title;
}

function hideAll() {
  activeMenu.value = null;
}

function register() {
  navigateTo({ name: $routeNames.register });
}

function toggleMobileMenu() {
  openSubHeaderMobile.value = !openSubHeaderMobile.value;
}

function openSearchBarMobileMenu() {
  openSearchBarMobile.value = true;
}

function redirectSublink(subitem: any) {
  if (externalLinks.includes(subitem.href)) {
    window.location.href = subitem.href;
    return;
  } else {
    if (process.env != undefined) {
      window.location.href = runtimeConfig.public.baseUrl + langCode.value + subitem.href;
      if (getUserData.value) {
        window.location.href = runtimeConfig.public.baseUrl + langCode.value + subitem.href;
      }
    }
  }
}

function toggleManageBidderAccountModal() {
  showManageBidderAccountModal.value = !showManageBidderAccountModal.value;
}

function toggleBailiffAccountModal() {
  showBailiffAccountModal.value = !showBailiffAccountModal.value;
}

onMounted(() => {
  loading.value = false;
});
</script>
